<template>
  <div>
    <!-- 号卡订单 -->
    <area-tree @setQueryParams="handleQuery" :treeShow="url ? false : true">
      <!-- 搜索栏 -->
      <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
        <el-form-item>
          <el-select clearable v-model="queryParams.handler" placeholder="请选择接单人姓名" v-if="!!orderOption" @change="refresh">
            <el-option v-for="item in orderOption" :label="item.extend.userName" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryParams.paiStatus" clearable
          placeholder="全部状态" @change="resetPageAndRefresh">
            <el-option label="待派单" :value="1"></el-option>
            <el-option label="已派单" :value="2"></el-option>
            <el-option label="已激活" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="queryParams.orderNum" clearable placeholder="搜索订单号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" icon="el-icon-search"  @click="refresh">搜索</el-button>
        </el-form-item>
        <el-form-item label="">
          <div class="lable-num">共 {{ fuseOrderTotal }} 单</div>
        </el-form-item>
      </el-form>

      <div class="content-wrap">
        <!-- <div class="btn-wrap cfx">
          <el-button type="success" size="small" icon="el-icon-plus" >添加套餐</el-button>
        </div> -->
        <!--列表-->
        <paged-table
          :data="fuseOrderList"
          :total="fuseOrderTotal"
          :loading="tLoading"
          :isShowSelection="false"
          defaultSortProp="id"
          v-model="queryParams"
          @refresh = "refresh"
        >
          <el-table-column
            prop="orderNum"
            label="订单号"
            width="170px"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="extend.userName"
            label="用户"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="account"
            label="学号"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="extend.schoolName"
            label="学校" v-if="url ? false : true"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="content"
            label="套餐名称"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="extend.userName"
            label="联系人"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="mobile"
            label="联系方式"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="room"
            label="联系地址"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <!-- <el-table-column
            prop="operator"
            label="运营商"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="room"
            label="宿舍号"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="phone"
            label="联系电话"
            :show-overflow-tooltip="true"
          >
          </el-table-column> -->
          <el-table-column
            prop="addTime"
            label="下单时间"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="activeTime"
            label="激活时间"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="paiTime"
            label="派单时间"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column prop="paiStatus"
            label="派单状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.activeStatus == 0">未激活</span>
            <span v-if="scope.row.paiStatus == 1">待派单</span>
            <span v-if="scope.row.paiStatus == 2">已派单</span>
            <span v-if="scope.row.paiStatus == 3">已激活</span>
          </template>
          </el-table-column>
          <el-table-column
            prop="extend.handlerUserName" label="接单人"
            :show-overflow-tooltip="true">

          </el-table-column>
          <!-- <el-table-column prop="resTime" label="派单时间" :show-overflow-tooltip="true">
          </el-table-column> -->

          <el-table-column align="right" label="操作"
            width="120" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" v-if="scope.row.paiStatus == 1" type="primary" @click="handleOrder(scope.row), orderVisible = true"
                icon="el-icon-s-order" title="派单"></el-button>
              <el-button size="mini" @click="handleDetails(scope.row)" 
              icon="el-icon-search" title="详情">
              </el-button>
            </template>
          </el-table-column>
        </paged-table>
      </div>
    </area-tree>

    <!-- 派单 -->
    <el-dialog title="派单" :visible.sync="orderVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="110px" size="small">
        <el-form-item label="接单人：" prop="orderNum" style="width: 90%">
          <el-select clearable filterable v-model="orderForm.handler" placeholder="请选择您想派发的校园达人" v-if="!!orderOption">
            <el-option v-for="item in orderOption" :label="item.extend.userName" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 60%">
          <el-button size="small" @click="orderVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="submitOrder">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog title="订单详情" :visible.sync="bVisible" width="800px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="detailsFileds" :model="details"  size="small">
        <el-form-item label="订单号：" prop="orderNum" style="width: 60%">
            <span v-text="details.orderNum" clearable></span>
        </el-form-item>
        <el-form-item label="套餐名称:" prop="content" style="width: 60%">
          <span v-text="details.content" clearable></span>
        </el-form-item>
        <el-form-item label="用户：" prop="extend.userName" style="width: 60%">
          <span v-text="details.extend.userName" clearable></span>
        </el-form-item>
        <el-form-item label="学号:" prop="account">
          <span v-text="details.account" clearable></span>
        </el-form-item>
        <el-form-item label="学校:" prop="extend.schoolName"  >
          <span v-text="details.extend.schoolName" clearable></span>
        </el-form-item>
        <el-form-item label="联系人:" prop="extend.userName"  >
          <span v-text="details.extend.userName" clearable></span>
        </el-form-item>
        <el-form-item label="联系方式:" prop="mobile"  >
          <span v-text="details.mobile" clearable></span>
        </el-form-item>
        <el-form-item label="联系地址:" prop="room"  >
          <span v-text="details.room" clearable></span>
        </el-form-item>
        <!-- <el-form-item label="运营商:" prop="operator" style="width: 60%">
          <span v-text="details.operator" clearable></span>
        </el-form-item>
        <el-form-item label="宿舍号:" prop="room" style="width: 60%">
          <span v-text="details.room" clearable></span>
        </el-form-item>
        <el-form-item label="联系电话:" prop="phone" style="width: 60%">
          <span v-text="details.phone" clearable></span>
        </el-form-item> -->
        <el-form-item label="下单时间:" prop="addTime" style="width: 60%">
          <span v-text="details.addTime" clearable></span>
        </el-form-item>
        <el-form-item label="派单状态:" prop="paiStatus" style="width: 60%">
          <span v-if="details.paiStatus == 0" clearable>未激活</span>
          <span v-if="details.paiStatus == 1" clearable>待派单</span>
          <span v-if="details.paiStatus == 2" clearable>已派单</span>
          <span v-if="details.paiStatus == 3" clearable>已激活</span>
        </el-form-item>
        <el-form-item label="派单时间:" prop="paiTime" style="width: 60%">
          <span v-text="details.paiTime" clearable></span>
        </el-form-item>
        <el-form-item label="接单人:" prop="extend.handlerUserName" style="width: 60%">
          <span v-text="details.extend.handlerUserName" clearable></span>
        </el-form-item>
        <el-form-item label="激活时间:" prop="activeTime" style="width: 60%">
          <span v-text="details.activeTime" clearable></span>
        </el-form-item>
        <el-form-item label="成员信息:" style="width: 100%">
          <el-table :data="details.children" border>
            <el-table-column prop="name" label="姓名" width="162"></el-table-column>
            <el-table-column prop="idCard" label="身份证号" width="162">
            </el-table-column>
            <el-table-column prop="cardNumber" label="手机号" width="162">
            </el-table-column>
            <el-table-column prop="activeStatus" label="激活状态" width="162">
              <template slot-scope="scope">
              <span v-if="scope.row.activeStatus == 0">未激活</span>
              <span v-if="scope.row.activeStatus == 1">已激活</span>
            </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <!-- <el-form-item label="派单时间:" prop="resTime" style="width: 60%">
          <span v-text="details.resTime" clearable></span>
        </el-form-item> -->
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseImport from "@/components/common/BaseImport";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import download from "@/mixins/download";
import { BaseUrl } from "@/common/config";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'fuseOrder',
  components: {
    AreaTree,
    PagedTable
  },
  mixins: [pageMixin,provinces,download],
  data() {
    return {
      url: '',
      BaseUrl,
      queryParams: {
        paiStatus: 1,
        mealType:2,
        orderStatus: 1,
        orderNum: '',
        handlerUserName: ''
      },
      form:{
        id:'',
        schoolName:'',
        department:'',
        province:'',
        city:'',
        area:'',
        level: '',
        remark: ''
      },
      schoolType: [],
      details:{
        orderNum:'',
        extend:{},
        account:'',
        cardNumber:'',
        cardName:'',
        operator:'',
        room:'',
        phone:'',
        appointTime:'',
        paiStatus:'',
        name:'',
        resTime:'',
        children: [],
      },
      handleOrderdetail: {
        schoolId: null,
      },
      orderVisible: false,
      orderForm: {
        id: null,
        handler: null,
      },
      orderOption: [],
      pageApi:'getFuseOrderList',
      loadApi:'getFuseOrderLoad',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("fuseOrder", ["fuseOrderList", "fuseOrderTotal"]),
  },
  methods: {
    ...mapActions("fuseOrder",[ "getFuseOrderList", "getFuseOrderLoad", "cardOrderAssign", "getAppRoleUsers" ]),
    async handleOrder(row) {
      this.orderForm.id = row.id
      let res = await this.getAppRoleUsers({schoolId:row.schoolId ,appRoleId: 3})
      this.orderOption = res
    },
    async submitOrder() {
      if(!this.orderForm.handler){
        return;
      }else{
        try{
          let res = await this.cardOrderAssign({ ...this.orderForm })
          if(!!res){ this.$message.success("派单成功") }
        }catch(error){}finally{
          this.orderVisible = false
          this.refresh()
        }
      }
    },
    handleQuery(data){
      // log(data)
      this.clearArea(false)
      if ((data.id + "").length === 9) { this.queryParams.province = data.id; }
      if ((data.id + "").length === 12) { this.queryParams.city = data.id; }
      if ((data.id + "").length === 15) { this.queryParams.area = data.id; }
      if ((data.id + "").length <9) {
        this.queryParams.schoolId = data.id;
        this.handleOrderdetail.schoolId = data.id
        this.handleOrder(this.handleOrderdetail)
      }
      this.refresh()
    },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      this.orderOption = []
      if(isRefresh) this.refresh();
    },
  },
  mounted(){
    this.handleOrder(this.handleOrderdetail)
    this.refresh()
    this.url = window.location.href.includes('sim') || window.location.href.includes('20033')
  }
};
</script>
<style lang="scss" scoped>
.lable-num { width: 100px; text-align: center; background: #28B892; color: #fff; border-radius: 5px; }
</style>
